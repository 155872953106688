<template>
<div class="wrapper">
  <h4>{{ titleName }}</h4>
  <el-form :model="form" ref="form" :rules="rules" label-width="100px">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item  label="老人姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="性别" prop="sex">
          <el-select
              v-model="form.sex"
              placeholder="请选择性别"
              style="width: 100%"
            >
              <el-option
                v-for="item in sexList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
          <el-form-item label="出生日期">
            <el-date-picker
              style="width: 100%"
              :default-value="'1940-06-01'"
              value-format="yyyy-MM-dd"
              v-model="form.birthDay"
              type="date"
              placeholder="请选择出生年月日"
              auto-complete="off"
            ></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="6">
            <el-col :span="6">
              <el-form-item label="户籍地址">
                <el-select v-model="form.province" placeholder="请选择省" style="width:100%;" @change="provinceChange">
                  <el-option
                    v-for="item in provinceList"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label-width="0">
                <el-select v-model="form.city" placeholder="请选择市" style="width:100%;" @change="cityChange">
                  <el-option
                    v-for="item in cityList"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label-width="0">
                <el-select v-model="form.area" placeholder="请选择区" style="width:100%;" @change="areaChange">
                  <el-option
                    v-for="item in areaList"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label-width="0">
                <el-select v-model="form.street" placeholder="请选择街道" style="width:100%;" @change="streetChange">
                  <el-option
                    v-for="item in streetList"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label-width="0">
                <el-select v-model="form.community" placeholder="请选择社区" style="width:100%;">
                  <el-option
                    v-for="item in communityList"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item  label="常住地址">
          <el-input v-model="form.address" placeholder="请输入常住地址" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item  label="身份证号">
          <el-input v-model="form.idNum" placeholder="请输入身份证号" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="护理等级">
          <el-input v-model="form.careLeve" placeholder="请输入护理等级" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="入住时间">
          <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd hh:mm:ss"
              v-model="form.enrtyOrgBeginTime"
              type="date"
              placeholder="请选择入住时间"
              auto-complete="off"
            ></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item  label="是否离院" prop="sex">
          <el-select
              v-model="form.leaveOrg"
              placeholder="是否离院"
              style="width: 100%"
            >
              <el-option
                v-for="item in isGoaway"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="离院时间">
          <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd hh:mm:ss"
              v-model="form.leaveOrgTime"
              type="date"
              placeholder="请选择离院时间"
              auto-complete="off"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="床位号">
          <el-input v-model="form.bedNum" placeholder="请输入床位号" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item  label="紧急联系人">
          <el-input v-model="form.mainPerson" placeholder="请输入紧急联系人" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label-width="160px" label="紧急联系人手机号">
          <el-input v-model="form.mainPersonCont" placeholder="请输入紧急联系人手机号" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item style="text-align: center;" label-width="0">
          <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
          <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions
} from "vuex";

export default {
  name: 'AddOrEdit',
  data() {
    return {
      titleName: '',
      form: {
        community: "测试",
      },
      communityList:[],
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true
      },
	    plateNoId: '',
      streetList:[],
      
      rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
          ],
        },
      areaList:[],
      cityList:[],
      provinceList:[],
      isGoaway: [
        {
          value: '是',
          label: '是'
        },
        {
          value: '否',
          label: '否'
        }
      ],
      sexList: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ]
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
	searchist() {
	      var list = [];
	      var _list = [];
	      var _this = this;
	      this.seqList.map(function (item) {
	        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
	          //然后把当前json添加到list数组中
	          _list.push(item);
	          list = _list;
	        }
	      });
	      return list;
	    }
  },
  created() {
    this.initView()
    this.getProvincesList()
  },
  methods: {
    ...mapActions([
      "addorgPerson",
      "updateWorkPersonServe",
      "addmealOrg",
      "updateWorkPersonServe",
      "getArealistServe",
      "getstrealistServe",
      "getProvincesServe",
      "getCitysServe",
      "getCommunitysServe"
    ]),
    getProvincesList() {
      this.getProvincesServe().then(res => {
        if(res.code == 200) {
          this.provinceList = res.data
        }
      })
    },
    provinceChange(val) {
      if(val) {
        this.getCitysList(val)
      }
    },
    // 获取市区
    getCitysList(val) {
      let params = {
        parenName: val
      }
      this.getCitysServe(params).then(res => {
        if(res.code == 200) {
          this.cityList = res.data
        }
      })
    },
    cityChange(val) {
      if(val) {
        this.getAreaList(val)
      }
    },
    // 获取区县list
    getAreaList() {
      this.getArealistServe().then((res) => {
        if (res.code == 200) {
          this.areaList = res.data;
        }
      });
    },
    areaChange(val) {
      if (val) {
        this.getStreetList(val);
      }
    },
    // 获取街道list
    getStreetList(val) {
      let params = {
        areaName: val,
      };
      this.getstrealistServe(params).then((res) => {
        if (res.code == 200) {
          this.streetList = res.data;
        }
      });
    },
	focusEvent1() {
	  this.$refs.xDown1.showPanel();
	},
	//获取水泥车数据
	plateNoClick(item) {
	  this.form.plateNoId = item.id;
	  this.plateNoId = item.plateNo;
	  this.$refs.xDown1.hidePanel();
	},
	getLeaveTime(data) {
	  this.form.leaveTime = data;
	},
  streetChange(val) {
      if(val) {
        this.getCommunitysList(val)
      }
    },
    // 获取社区
    getCommunitysList(val) {
      let params = {
        parenName: val
      }
      this.getCommunitysServe(params).then(res => {
        if(res.code == 200) {
          this.communityList = res.data
        }
      })
    },
	getFactTime(data) {
	  this.form.factTime = data;
	},
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加老人信息'
        this.itemData = JSON.parse(this.$route.query.item);
        this.form.orgId = this.itemData.id
      } else {
        this.titleName = '修改老人信息'
        this.itemData = JSON.parse(this.$route.query.item);
        //这里调用请求编辑时的详情接口渲染form表单数据
        this.form = {
          address: this.itemData.address,
          id: this.itemData.id,
          area : this.itemData.area,
          bedNum : this.itemData.bedNum,
          birthDay : this.itemData.birthDay,
          careLeve : this.itemData.careLeve,
          city : this.itemData.city,
          community : this.itemData.community,
          enrtyOrgBeginTime : this.itemData.enrtyOrgBeginTime,
          leaveOrgTime : this.itemData.leaveOrgTime,
          mainPerson : this.itemData.mainPerson,
          leaveOrg : this.itemData.leaveOrg,
          mainPersonCont : this.itemData.mainPersonCont,
          orgId : this.itemData.orgId,
          province : this.itemData.province,
          street : this.itemData.street,
          sex : this.itemData.sex,
          name : this.itemData.name,
          idNum : this.itemData.idNum
        }
      }
    },
    //区域选择
    selectArea(data) {
      this.form.teamName = '';
      this.teamByAreaList = '';
      this.getTeamListByArea(data); //获取队伍
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          let server = "addorgPerson"
          this[server](params).then(res => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: "success"
              });
              this.$router.go(-1)
            }
            this.loading = false;
          })
        } else {
          return false;
        }
      });
      
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
      height: 200px;
      overflow: auto;
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #dcdfe6;
	  padding: 0 8px;
      li {
        height: 32px;
        line-height: 32px;
        cursor: pointer;
      }
    }
}
</style>
